@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  background: #000;
  color: #fff;
  font-family: "Work Sans";
}

.element {
  padding-bottom: 20px;
}